<template>
  <div v-if="event.speakers.length !== 0" class="flex">
    <FaIcon icon-class="fal fa-users" classes="mt-[3.5px] mr-[10px] fa-fw" />
    <div>
      {{ getSpeakers() }}
    </div>
  </div>
  <div class="flex space-x-xs">
    <div>
      <FaIcon icon-class="fal fa-calendar-day" classes="mr-[10px] fa-fw" />
      <div class="inline">
        <span>{{
          event.date ? formatDate(event.date) : t('event.individualAppointment')
        }}</span>
        <span v-if="event.additionalDates?.length > 0">
          <span v-if="event.hasConsecutiveDates"
            >{{ ' + '
            }}{{
              formatDate(
                event.additionalDates[event.additionalDates.length - 1].date,
              )
            }}</span
          >
          <template v-else>
            <span v-if="event.additionalDates.length === 1">
              {{ ' + ' }}{{ t('event.additionalDate') }}</span
            >
            <span v-else>
              {{ ' + ' }}
              {{
                t('event.additionalDates', {
                  count: event.additionalDates.length,
                })
              }}</span
            >
          </template>
        </span>
      </div>
    </div>
    <div v-if="event.additionalDates?.length === 0 && event.date">
      <span class="mr-xs">|</span>
      <FaIcon icon-class="fal fa-clock" classes="mr-2xs fa-fw" />
      {{
        t(event.duration == 1 ? 'event.duration' : 'event.durations', {
          duration: event.duration,
        })
      }}
    </div>
  </div>
  <div>
    <div>
      <FaIcon icon-class="fal fa-location-pin" classes="mr-[10px] fa-fw" />
      <span>{{ event.location.title }}</span
      ><span v-if="event.courseType === ECourseType.HYBRID">
        {{ `&nbsp;${t('event.onlineLocationAddition')}` }}</span
      >
    </div>
  </div>
</template>
<script setup lang="ts">
import { formatDate } from '@/helpers/dayJs';
import { ECourseType } from '@/@types/course-type';

import type { EventData } from '~~/src/server/transformers/EntityTransformers/eventTransformer';
import FaIcon from '@/components/fa-icon.vue';

const { t } = useTrans();

const props = defineProps({
  event: {
    type: Object as PropType<EventData>,
    required: true,
  },
});

function getSpeakers(): string {
  let speakerInfo = '';
  props.event.speakers.forEach((speaker, index) => {
    speakerInfo += `${
      speaker.title
        ? `${speaker.title} ${speaker.firstName} ${speaker.lastName}`
        : `${speaker.firstName} ${speaker.lastName}`
    }${
      index !== props.event.speakers.length - 1 &&
      props.event.speakers.length !== 1
        ? ', '
        : ''
    }`;
  });
  return speakerInfo;
}
</script>
<style scoped lang="postcss"></style>

<template>
  <TileFrame :size="size" @click="handleTileClick">
    <template #image>
      <ImageBasic
        :image-data="tileData.image"
        class="object-cover w-full h-full aspect-video"
        :class="{ ['grayscale-[88%] ease-in duration-300']: !isAvailable }"
      ></ImageBasic>
      <EventStatusIndicator
        v-if="
          tileData.typeHandle === TileTypeHandle.EVENT &&
          tileData.event.status !== EEventStatus.NO_STATUS
        "
        :event-status="tileData.event.status"
      />
    </template>

    <div
      class="grid h-full p-md grid-rows-[auto_1fr_auto]"
      :class="{
        'grid-rows-[min-content_max-content_auto]':
          !isSingleTile && size === ETileSize.SLIM,
        'grid-rows-[min-content_max-content] content-between':
          isSingleTile && size === ETileSize.SLIM,
      }"
    >
      <div class="flex justify-between">
        <div>
          <h5 ref="overline" class="flex items-start text-sm font-light">
            <div
              class="bg-sign-yellow text-white h-[24px] min-w-[75px] shrink-0 px-xs inline float-left text-sm leading-sm mr-sm"
            >
              <FaIcon
                icon-class="fal fa-calendar-days"
                classes="pr-2xs fa-fw"
              />
              {{ t(tileData.event.type) }}
            </div>
            <div>{{ tileData.overline.map(t).join(', ') }}</div>
          </h5>
          <div class="text-md pt-sm">
            <h4 class="break-words">
              {{ tileData.title }}
            </h4>
          </div>
        </div>
        <div
          v-if="!isMobile"
          class="flex flex-row flex-wrap justify-end space-x-1 md:space-x-4"
        >
          <EventCourseIndicator
            v-if="size === ETileSize.SLIM"
            :course-type="tileData.event.courseType"
            :is-clickable="false"
          />
          <EventCmeIndicator
            v-if="size === ETileSize.SLIM && tileData.event.cmePoints"
            :cme-points="tileData.event.cmePoints"
          />
        </div>
      </div>
      <SingleTileContent
        v-if="isSingleTile"
        :tile-data="tileData"
        :size="size"
        :query-args="queryArgs"
      />
      <MultiTileContent
        v-else
        :tile-data="tileData"
        :size="size"
        :query-args="queryArgs"
      />
    </div>
  </TileFrame>
</template>
<script setup lang="ts">
import { ETileSize } from '@/@types/tile-size';
import type { LocationQueryRaw } from 'vue-router';
import EventCourseIndicator from '@/components/event/event-course-indicator.vue';
import EventCmeIndicator from '@/components/event/event-cme-indicator.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import { EEventStatus } from '~~/src/@types/event-status';
import TileFrame from '../../tileFrame/TileFrame.vue';
import SingleTileContent from './components/SingleTileContent.vue';
import MultiTileContent from './components/MultiTileContent.vue';
import ImageBasic from '@/components/components/core/basicImage/image-basic.vue';
import type { EventTileData } from './tileType';
import { TileTypeHandle } from '../../../types';
import FaIcon from '@/components/fa-icon.vue';
import EventStatusIndicator from '@/components/event/event-status-indicator.vue';

const overline = ref<HTMLDivElement>(null);

defineExpose({ overline });

const isMobile = inject(mq_breakpointIsMobile);
const { t } = useTrans();

const props = defineProps({
  tileData: {
    type: Object as PropType<EventTileData>,
    required: true,
  },
  size: {
    type: String as PropType<ETileSize>,
    required: true,
  },
  isSingleTile: {
    type: Boolean,
    default: false,
  },
  queryArgs: {
    type: Object as PropType<LocationQueryRaw>,
    required: false,
    default: null,
  },
});

const isAvailable =
  props.tileData.event.status !== EEventStatus.CANCELLED &&
  props.tileData.event.status !== EEventStatus.FULLY_BOOKED;

function handleTileClick() {
  navigateTo({
    path: '/' + props.tileData.uri,
    query: props.queryArgs,
  });
}
</script>
<style scoped lang="postcss"></style>

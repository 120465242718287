<template>
  <div
    class="status"
    :class="{
      'status-popular': eventStatus === EEventStatus.POPULAR,
    }"
  >
    <div class="status-main">
      {{ getStatusFromEnum(eventStatus) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { EEventStatus } from '@/@types/event-status';

const { t } = useTrans();
defineProps({
  eventStatus: {
    type: String as PropType<EEventStatus>,
    required: true,
  },
});

function getStatusFromEnum(icon: EEventStatus) {
  switch (icon) {
    case EEventStatus.FULLY_BOOKED:
      return t('event.fullyBooked');
    case EEventStatus.CANCELLED:
      return t('event.cancelled');
    case EEventStatus.POPULAR:
      return t('event.popular');
    default:
      return '';
  }
}
</script>
<style scoped lang="postcss">
.status {
  @apply absolute top-md right-0 bg-vdv-btn-base text-white;
}

.status-main {
  @apply relative px-xs font-bold text-sm h-[24px] leading-sm;
}
.status-popular {
  @apply bg-sign-yellow;
}

.status-main::before {
  content: '\A';
  border-style: solid;
  border-width: 0px 10px 24px 0;
  border-color: transparent #555555 transparent transparent;
  position: absolute;
  left: -10px;
}

.status-popular > .status-main::before {
  border-color: transparent #deab53 transparent transparent;
}
</style>

<template>
  <div
    :class="{
      'flex flex-row justify-between items-end':
        size === ETileSize.SLIM && !isMobile,
    }"
  >
    <div
      class="pt-sm"
      :class="{
        'flex justify-between items-center': size === ETileSize.SLIM,
      }"
    >
      <div class="text-md">
        <EventInfos :event="tileData.event" />
      </div>
    </div>
    <div class="flex flex-col self-end justify-end pt-md">
      <div class="relative flex items-center justify-between">
        <div class="flex flex-row flex-wrap space-x-1 md:space-x-4">
          <EventCourseIndicator
            v-if="size !== ETileSize.SLIM || isMobile"
            :course-type="tileData.event.courseType"
            :is-clickable="false"
          />
          <EventCmeIndicator
            v-if="
              tileData.event.cmePoints && (size !== ETileSize.SLIM || isMobile)
            "
            :cme-points="tileData.event.cmePoints"
          />
        </div>
        <div v-if="tileData.event.price" class="flex flex-col text-right">
          <span class="text-3xl font-bold">
            {{ n(tileData.event.price, 'currency') }}
          </span>
          <span class="text-sm mt-[-3pt]">
            {{
              tileData.event.isGroupPrice
                ? t('event.tax.prefix.group')
                : t('event.tax.prefix.person')
            }}
            {{ t('event.tax') }}
          </span>
        </div>
        <div v-else class="flex flex-col text-right">
          <span class="text-2xl font-bold">{{ t('event.forFree') }}</span>
        </div>
      </div>
      <LinkBasic
        :label="t('event.showDetails')"
        :link="`/${tileData.uri}`"
        :filter-params="queryArgs"
        target="_self"
        icon="fas fa-chevron-right"
        class="justify-end text-vdv-base pt-md"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ETileSize } from '@/@types/tile-size';
import EventCourseIndicator from '@/components/event/event-course-indicator.vue';
import EventCmeIndicator from '@/components/event/event-cme-indicator.vue';
import LinkBasic from '@/components/components/core/basicLink/basic-link.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import EventInfos from './EventInfos.vue';
import type { LocationQueryRaw } from 'vue-router';
import type { EventTileData } from '../tileType';

const { t, n } = useTrans();

const overline = ref<HTMLDivElement>(null);

defineExpose({ overline });

const isMobile = inject(mq_breakpointIsMobile);

defineProps({
  tileData: {
    type: Object as PropType<EventTileData>,
    required: true,
  },
  size: {
    type: String as PropType<ETileSize>,
    required: true,
  },
  queryArgs: {
    type: Object as PropType<LocationQueryRaw>,
    required: false,
    default: null,
  },
});
</script>
<style scoped lang="postcss"></style>
